import { render, staticRenderFns } from "./pjCollection.vue?vue&type=template&id=339b863c&scoped=true&"
import script from "./pjCollection.vue?vue&type=script&lang=js&"
export * from "./pjCollection.vue?vue&type=script&lang=js&"
import style0 from "./pjCollection.vue?vue&type=style&index=0&id=339b863c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "339b863c",
  null
  
)

export default component.exports